import React from 'react'
import classNamesBind from 'classnames/bind'
import styles from './App.module.scss'
import logo from '../../assets/images/logo.jpg'
import team from '../../assets/images/team.jpg'
import { AppProps } from './App.d'

const cx = classNamesBind.bind(styles)

const App: React.FC<AppProps.Props> = props => {
	const { className } = props

	return (
		<div className={cx('wrapper', className)}>
			<img className={cx('logo')} src={logo} alt='' />
			<h1>Team</h1>
			<img className={cx('image')} src={team} alt='' />
		</div>
	)
}

// const mapStateToProps = (state: IAppState): AppProps.Store => ({ });

// const mapDispatchToProps: MapDispatchToProps<
// 	AppProps.Dispatch,
// 	AppProps.Own
// > = (dispatch: TDispatch) => ({ });

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(App);

export default App
